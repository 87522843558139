<template>
  <ModuleForm
    :module-name="$tc('moduleForm.infobox')"
    :module="infobox"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$tc('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="infobox.id"
        :record-id="infobox.id"
        store-name="infobox"
        list-router-path="infobox"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/infobox')"
       />
    </template>
    <template #left-column>
      <Input
        v-model="infobox.title"
        @blur="$v.infobox.title.$touch()"
        :error="$v.infobox.title.$error"
        id="infobox_title"
        :label="$tc('infobox.title')"
        :placeholder="$tc('infobox.title_placeholder')"
        :disabled="disabled"
      />
      <Input
        v-model="infobox.heading"
        @blur="$v.infobox.heading.$touch()"
        :error="$v.infobox.heading.$error"
        id="infobox_heading"
        :label="$tc('infobox.heading')"
        :placeholder="$tc('infobox.heading_placeholder')"
        :disabled="disabled"
      />
      <Select
          v-model="infobox.sizeType"
          :options="sizeTypeOptions"
          id="infobox_sizeType"
          :label="$tc('infobox.size_type')"
          no-empty-value
          :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <div class="published-dates" v-if="false">
        <Datepicker
          class="published-dates__datepicker"
          v-model="infobox.publishedSince"
          id="infobox_publishedSince"
          :label="$tc('infobox.published_since')"
          :placeholder="$tc('infobox.published_since_placeholder')"
          :show-set-now="!disabled"
          :disabled="disabled"
        />
        <Datepicker
          class="published-dates__datepicker"
          v-model="infobox.publishedUntil"
          id="infobox_publishedUntil"
          :label="$tc('infobox.published_until')"
          :placeholder="$tc('infobox.published_until_placeholder')"
          :disabled="disabled"
        />
      </div>
      <div class="title-text">
        {{ $tc('infobox.bodytext') }}
      </div>
      <RichTextEditor
        v-model="infobox.bodyText"
        :other-options="richTextEditorConfig"
        id="bodyText"
        rows="10"
        :readonly="disabled"
      />
    </template>
    <template #right-column>
      <div class="title-text">
        {{ $tc('infobox.image') }}
      </div>
      <ModuleFormPhotoBox
        :image="infoboxImage"
        :disabled="disabled"
        @set-media="setImage"
        @remove-media="removeImage"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="infobox.unpublished"
        id="infobox_setUnpublished"
        :label="$tc('media.set_unpublished')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import RichTextEditor from '@/components/form/RichTextEditor'
import InfoboxModel from '@/model/InfoboxModel'
import NotifyService from '@/services/NotifyService'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import Datepicker from '@/components/form/Datepicker'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import SizeTypeNmh from '@/model/ValueObject/InfoboxSizeTypeNmh'
import SizeTypeVlm from '@/model/ValueObject/InfoboxSizeTypeVlm'
import Select from '@/components/form/select/Select'
import { mapGetters } from 'vuex'

export default {
  name: 'InfoboxNewView',
  data () {
    return {
      infobox: this._.cloneDeep(InfoboxModel),
      richTextEditorConfig: TinyMceConfig.getConfig(),
      sizeTypeData: {
        nmh: SizeTypeNmh,
        vlm: SizeTypeVlm
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    infobox: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      heading: {
        required,
        minLength: minLength(3),
        maxLength: maxLength
      }
    }
  },
  components: {
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    RichTextEditor,
    Datepicker,
    ModuleForm,
    OutlinedCheckbox,
    Select
  },
  computed: {
    ...mapGetters(['vlm']),
    infoboxImage () {
      return this.$store.getters['infobox/infoboxImage']
    },
    sizeTypeOptions () {
      return this.vlm ? this.sizeTypeData.vlm : this.sizeTypeData.nmh
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.infobox.image = null
      if (this.$store.getters['infobox/infoboxImage']) {
        this.infobox.image = this.$store.getters['infobox/infoboxImage'].id
      }
      this.$store.dispatch('infobox/create', this.infobox)
        .then(() => {
          if (this.$store.getters['infobox/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.infobox = this.$store.getters['infobox/detail']
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['infobox/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('infobox/storeInfoboxImage', media)
      })
    },
    removeImage () {
      this.$store.commit('infobox/storeInfoboxImage', null)
    },
    goToEdit () {
      this.$router.push('/infobox/' + this.infobox.id + '/edit')
    }
  },
  created () {
    this.$store.commit('infobox/storeInfoboxImage', null)
  }
}
</script>
<style scoped lang="scss">
.published-dates {
  display: flex;
  gap: 2%;
  &__datepicker {
    flex: 0 0 49%;
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>
